import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { IoMdArrowDropleft } from "react-icons/io";
import specificImg1 from "../../assets/images/spn.png";
import { useMediaQuery } from "react-responsive";

import NewsRealData from "../../features/news";
import loading from "../../assets/images/loading.gif";
import CategoriesGetData from "../../features/categories";
import { dateConvertor } from "../../utility/dateConvertor";
import { MdNavigateBefore } from "react-icons/md";
import Loading from "../../components/Loading";
import ProvincialNews from "../../features/provincialNews";

export const NewsSection = ({ classname = "" }) => {
	const [lastTwo, setLastTwo] = useState(null)
	const navigate = useNavigate();
	const is2x = useMediaQuery({ query: "(max-width:1535px)" });
	const isLargeScreen = useMediaQuery({ query: "(max-width:1024px)" });
	const newsQuery = NewsRealData("news");

	useEffect(() => {
		// console.log(newsQuery);
		if (newsQuery) {
			// console.log("🎆", newsQuery.data);
			const filteredData = newsQuery.data?.data?.data?.filter(
				(item) => item.url[0] !== null
			);
			if (filteredData !== undefined) {
				setLastTwo(filteredData.slice(-2));
				console.log(lastTwo);
				
			}
		}
	}, [newsQuery.data]);

	const catQuery = CategoriesGetData("category"); //real ones
	const provinceQuery = ProvincialNews("news/index") // province data


	

	// const sortedDate  = catQuery.data.data.data[0].news && catQuery.data.data.data[0].news.sort((a,b)=> new Date(b.created_data) - new Date(a.created_data))
	// const lastTwoSorted = sortedDate.slice(0,2)
	// console.log(lastTwoSorted);

	
	// console.log(' 🟢🔴', provinceQuery )
	// #############################################################################
	// const fetchData = async () => {
	// 	try {
	// 		const response = await fetch("https://farasco.ir/test/api/V1/news", {
	// 			headers: {
	// 				"Content-Type": "application/json",
	// 				Accept: "application/json",
	// 			},
	// 		});
	// 		const json = await response.json();
	// 		console.log(json);
	// 	} catch (error) {
	// 		console.log("error", error);
	// 	}
	// };
	// const newsQuery = useQuery({
	// 	queryKey:["news"] ,
	// 	queryFn: fetchData()
	// })
	// const catQuery = useQuery({
	// 	queryKey:['cat'],
	// 	queryFn:getRequest("category",{
	// 		headers: {
	// 			"Content-Type": "application/json",
	// 			Accept: "application/json",
	// 		}})
	// })
	// const navigate = useNavigate()

	// console.log(catQuery, "🎈");
	// console.log("🎇", newsQuery.data);
	// let tmp  = newsQuery.

	// const data = newsQuery.data?.data?.data
	// console.log( "this is fucking data ",data);
	// const findLastTwo = () => {
	// 	let filteredData = null
	// 	if(!!data){
	// 		console.log("🎭");

	// 		filteredData = data.filter(item => item.url[0]!== null)
	// 		setLastTwo(filteredData.slice(-2))
	// 	}else {
	// 		console.log("there is no fucking data");
	// 	}
	// }
	// findLastTwo()

	// let lastTwo = null
	// if(filteredData) {
	// 	lastTwo = filteredData.slice(-2)
	// 	return lastTwo
	// }
	// console.log(lastTwo,"💍");

	// #############################################################################

	let displayCount = !isLargeScreen ? 6 : 2;


	const handleProvinceData = (data)=>{
		sessionStorage.setItem('province_data' , JSON.stringify(data))
	}

	return (
		<>
			<article className={`${classname} lg:pt-50 p-16`}>
				<h2 className="hidden lg:block text-center font-bold text-4xl xl:text-5xl text-primary">
					اخــــبار
				</h2>

				{/* #################################################### */}
				{/* #################### hot news ###################### */}
				{/* #################################################### */}

				{/* <h3 className="flex justify-between my-10  lg:text-white lg:text-[22px] text-3xl xl:text-4xl text-center lg:text-start text-primary gap-2 ">
					<span className="flex-grow" >سرتیتر خــبرها	</span>
				</h3> */}

				<div className=" flex flex-col lg:!flex-row lg:justify-between gap-2  ">

					{/* ********************** right box top news ********************** */}

					<section className="flex flex-col flex-grow my-10 lg:text-white lg:text-[22px] text-3xl xl:text-4xl text-center lg:text-start text-primary gap-5 ">
						<span>سرتیتر خــبرها </span>
						<div className="flex flex-grow flex-col items-start justify-start font-bold text-base md:text-xl 2xl:text-2xl text-primary border-primary border-2 rounded-2xl p-4 gap-3 basis-1/2 max-h-40 lg:max-h-60 overflow-y-scroll overflow-x-hidden hide-scrollbar">
							{catQuery && catQuery.isFetching ? (
								<Loading />
							) : catQuery.isError ? (
								<p className="text-center pt-3 text-2xl text-white">
									{"مشکلی در دریافت دیتا پیش آمده آست  "}
								</p>
							) : (
								newsQuery.data?.data?.data
									// .slice(0, displayCount)
									.map((item) => (
										// <TextCart key={item.id} {...item} btnText={<MdNavigateBefore className="text-white hover:text-primary" />} />
										<NavLink
											className="flex justify-between items-center w-full"
											key={item.id}
											to={`news/${item.id}`}
										>
											{item.title}
											<MdNavigateBefore className=" hover:text-primary text-white bg-primary rounded-full lg:rounded-md hover:bg-transparent hover:border-primary hover:border-[1px] hover:rounded-lg" />
										</NavLink>
									))
							)}
						</div>
					</section>

					{/* ********************** left box city news ********************** */}
					<section className="flex flex-col flex-grow my-10 lg:text-white lg:text-[22px] text-3xl xl:text-4xl text-center lg:text-start text-primary gap-5">
						<span>اخبار استان ها</span>
						<div className="flex flex-grow flex-col items-start justify-start font-bold text-base md:text-xl 2xl:text-2xl text-primary border-primary border-2 rounded-2xl p-4 gap-3 basis-1/2 max-h-40 lg:max-h-60 overflow-y-scroll overflow-x-hidden hide-scrollbar">
							{provinceQuery && provinceQuery.isFetching ? (
								<Loading />
							) : provinceQuery.isError ? (
								<p className="text-center pt-3 text-xl text-white">
									{"مشکلی در دریافت دیتا پیش آمده آست  "}
								</p>
							) : (
								<>
									{/* {console.log(' data🔴', provinceQuery?.data?.data )} */}
									{provinceQuery.data?.data
										.filter((item) => item.news.length > 0)
										.map((item) => (
											<>
												<h3
													key={item.id}
													className="flex items-center w-full"
												>
													{/* <div className="w-full flex justify-between items-center"> */}

													<span className=" hover:text-primary hover:transition-all 2xl:text-2xl 2xl:py-2">
														{item.name}
													</span>

													{/* </div> */}
												</h3>
												{/* {console.log(' 2️⃣', item.news )} */}
												{item?.news.map((sub) => (
													<NavLink
														onClick={() =>handleProvinceData({...sub})}
														key={sub.id}
														to={`/news/province/${sub.id}`}
														className="flex justify-start gap-3 items-center w-full mr-3"
													>
														<div className="flex justify-start gap-3 items-center w-full mr-3">
															<h4 className="text-white font-thin 2xl:text-xl ">
																{sub.title}
															</h4>
															<span className="text-white 2xl:text-xl ">
																{sub.created_at &&
																	dateConvertor(
																		sub.created_at
																	)}
															</span>
														</div>

														<MdNavigateBefore className=" hover:text-primary text-white bg-primary rounded-full lg:rounded-md hover:bg-transparent hover:border-primary hover:border-[1px] hover:rounded-lg ml-2" />
													</NavLink>
												))}
											</>
										))}
								</>
							)}
						</div>
					</section>

					{/* {catQuery && catQuery.isFetching ? (
						<Loading/>
					) : catQuery.isError ? (
						<p className="text-center pt-3 text-2xl text-white">
							{"مشکلی در دریافت دیتا پیش آمده آست  "}
						</p>
					) : (
						<div
							className={`flex gap-4 flex-col flex-1 md:flex-row md:flex-wrap lg:w-full`}
						>
							{newsQuery.data?.data?.data
								.slice(0, displayCount)
								.map((item) => (
									<TextCart key={item.id} {...item} btnText={<MdNavigateBefore className="text-white hover:text-primary" />} />
								))} */}
					{/* {fakedata.slice(0, displayCount).map((item) => (
								<TextCart key={item.id} {...item} />
							))} */}
					{/* </div>
					)} */}
				</div>
			</article>

			{/* #################################################### */}
			{/* ################# categories section ###############  */}
			{/* #################################################### */}

			<section className="flex justify-between px-16 pb-20 gap-28 ">
				{!isLargeScreen && (
					<div className="sp1 flex flex-col text-white gap-3 ">
						<h3 className="text-white text-[22px] 2xl:text-3xl">
							دسته‌بندی اخبار
						</h3>
						{catQuery.isFetching ? (
							<Loading />
						) : catQuery.isError ? (
							<p className="text-center pt-3 text-xl text-white">
								{"مشکلی در دریافت دیتا پیش آمده آست  "}
							</p>
						) : (
							<>
								{catQuery.data?.data?.data.map((item) => (
									<NavLink
										to={"/news"}
										key={item.id}
										className="flex items-center"
									>
										<IoMdArrowDropleft className="text-primary" />
										<p className=" hover:text-primary hover:transition-all 2xl:text-2xl 2xl:py-2">
											{item.name}
										</p>
									</NavLink>
								))}
							</>
						)}
						{lastTwo &&
							lastTwo.map((item) => (
								<NavLink
									to={"/news"}
									className=" relative rounded-3xl flex overflow-hidden bg-test2"
								>
									<img
										width={is2x ? 250 : 350}
										className="drop-shadow-2xl"
										src={item.url[0]}
										alt=" "
									/>
									<div className="absolute bottom-2 w-full text-lg">
										<p className=" text-center bg-primary bg-opacity-70 ">
											{item.title}
										</p>
										<p className=" text-center bg-primary bg-opacity-70 ">
											{dateConvertor(item.created_data)}
										</p>
									</div>
								</NavLink>
							))}
					</div>
				)}

				<div className="flex-grow flex flex-col gap-5 text-white">
					{isLargeScreen && (
						<div className="flex justify-between items-center">
							<h3 className="text-primary text-2xl lg:text-4xl font-bold">
								اخبــــــار
							</h3>
							<button
								on
								className=" border-[1px] border-primary rounded-xl text-primary px-3 py-1"
							>
								اخبار بیشتر{" "}
							</button>
						</div>
					)}
					{catQuery.isFetching ? <Loading/>  : catQuery.isError ? <div> something went wrong</div> : catQuery.data.data.data[0].news.sort((a,b)=> new Date(b.created_data) - new Date(a.created_data)).slice(0,2).map(item=>(

						<NavLink to={`news/${item.id}`} style={{backgroundImage:`url(${item.image})`}} key={item} className="flex rounded-3xl justify-end items-end bg-center bg-cover bg-no-repeat  lg:h-full h-60 overflow-hidden ">
							<span className="bg-primary  px-7 py-2 bg-opacity-60 text-whites drop-shadow-md w-full">
								{item.title}  | {dateConvertor(item.created_data)}
							</span>
						</NavLink>
					))}
					{/* <article className="flex rounded-3xl justify-end items-end bg-center bg-cover bg-no-repeat bg-[url('./assets/images/n2.png')] lg:h-full h-60 ">
						<span className="px-7 py-4">
							جشنواره زیبایی | مشهد | 1402/12/02{" "}
						</span>
					</article> */}
				</div>
			</section>
			{!isLargeScreen && (
				<div className="flex justify-center px-20">
					<button
						onClick={() => navigate("/news")}
						className=" border-[1px] border-primary rounded-xl text-primary px-3 py-1"
					>
						اخبار بیشتر{" "}
					</button>
				</div>
			)}
		</>
	);
};
