import React, { useRef, useState } from "react";
import glp1 from "../../assets/images/glp1.png";
import glp2 from "../../assets/images/glp2.png";
import glp3 from "../../assets/images/glp3.png";
import { MainContainer } from "../../layouts/main container/mainContainer";
import { Category } from "./Category.component";
import { ContactForm } from "../../components/ContactForm.component";
import halfCircle from "../../assets/images/rightHalf.png";
import { NewsCarousel } from "../../components/NewsCarousel";
import { useMediaQuery } from "react-responsive";
import { SearchBar } from "../../components/Searchbar";
import NewsRealData from "../../features/news";
import loading from "../../assets/images/loading.gif";
import st from "../../styles/news/news.module.css";
import TagsComponent from "./TagsComponent";
import NewsCart from "../../components/NewsCart";

const fakeData = [
	{
		id: 1,
		image: glp1,
		title: "title 1",
		body: " this is forst fsddsd text",
		created_data: "2024-04-30T11:34:28.000000Z",
		visitCount: "10",
		code: "1234",
	},
	{
		id: 2,
		image: glp2,
		title: "title 2",
		body: "this is second text ",
		created_data: "2024-04-30T11:34:28.000000Z",
		visitCount: "150",
		code: "1234",
	},
	{
		id: 3,
		image: glp3,
		title: "title 2",
		body: "this is second text ",
		created_data: "2024-04-30T11:34:28.000000Z",
		visitCount: "150",
		code: "1234",
	},
	{
		id: 4,
		image: glp2,
		title: "title 2",
		body: "this is second text ",
		created_data: "2024-04-30T11:34:28.000000Z",
		visitCount: "152",
		code: "1234",
	},
];

const News = () => {
	const isLargeScreen = useMediaQuery({ query: "(max-width:1024px)" });
	const isXl = useMediaQuery({ query: "(max-width:1400px)" });
	const isMedium = useMediaQuery({ query: "(max-width:820px)" });
	const isSmall = useMediaQuery({ query: "(max-width:530px)" });
	const scrollRef = useRef(null)
	const [catTitle, setCatTitle] = useState("");
	const [tagData, setTagData] = useState({
		code: "...",
		visitCount: "...",
		created_data: false,
		newsImage: "",
		newsBody: "",
		newsTitle: "",
		id: 0,
	});
	const [categoryState, setCategoryState] = useState({
		title: " ",
		filteredCode: -1,
		filteredData: undefined,
	});

	const settings = {
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: isLargeScreen ? (isMedium ? 1 : 2) : isXl ? 3 : 4,
		slidesToScroll: 1,
	};

	const newsQuery = NewsRealData("category");
	
	

	return (
		<MainContainer>
			{!isLargeScreen ? (
				<>
					<div className="mt-72"></div>
					<Category
						setCatTitle={setCatTitle}
						state={categoryState}
						setState={setCategoryState}
						className={`pt-20 px-[120px] flex justify-between items-stretch`}
					/>
				</>
			) : (
				// <>
				<div className="text-primary font-bold  ">
					<h1 className="text-center text-3xl pt-14 pb-5">اخبــــار</h1>
					<SearchBar className={"mx-10 md:w-2/3 md:m-auto"} />
					<Category
						setCatTitle={setCatTitle}
						state={categoryState}
						setState={setCategoryState}
						className={`pt-20  flex justify-center items-stretch`}
					/>
				</div>
				// {/* </> */}
			)}

			<section className="pt-20 pb-10 px-10 md:px-[120px]">
				{isLargeScreen &&
					(catTitle === "" ? (
						""
					) : (
						<h2 className=" text-center text-primary font-bold text-lg lg:text-[28px]">
							{catTitle}
						</h2>
					))}
				{newsQuery.isLoading ? (
					<div className="flex justify-center text-white">
						<img width={50} height={50} src={loading} alt=" loading..." />
					</div>
				) : newsQuery.isError ? (
					<p className="text-center pt-3 text-2xl text-white">
						Error:{" "}
						{newsQuery.error.message || "مشکلی در سرور رخ داده است "}
					</p>
				) : categoryState.filteredData ? ( // what the fuck happen here ????
					<>
					{/* {console.log(categoryState.filteredData)} */}
					
					<NewsCarousel scrollRef={scrollRef}
						// data={fakeData}/
						data={
							categoryState.filteredData !== undefined
								? categoryState.filteredData
								: fakeData
						}
						settings={settings}
						isLgScreen={isLargeScreen}
						setTagData={setTagData}
						tagData={tagData}
					/>
					</>
				) : (
					<div className=" hidden lg:!block w-full text-center ">
						<span className={` text-primary m-auto ${st.blink}`}>
							⚠لطفا یک دسته بندی انتخاب کنید ⚠
						</span>
					</div>
				)}

				{/* ######################################## */}
				{/* ################# tags ################# */}
				{/* ######################################## */}

				{categoryState.filteredData && <TagsComponent ref={scrollRef}  tagData={tagData} />}
			</section>

			{/* ######################################## */}
			{/* ############## news cart ############### */}
			{/* ######################################## */}

			<NewsCart data={tagData} />

			{!isLargeScreen && (
				<section className="px-10">
					<ContactForm />
				</section>
			)}
		</MainContainer>
	);
};

export default News;