import React from "react";
import halfCircle from "../assets/images/rightHalf.png";
import { useMediaQuery } from "react-responsive";


const NewsCart = ({data}) => {
   const isSmall = useMediaQuery({ query: "(max-width:530px)" });
	return (
		<article className="flex flex-col lg:flex-row justify-between relative items-center gap-10 py-10 md:py-20 px-10 md:px-[120px] ">
			<figure className="overflow-hidden w-3/5  sm:w-2/5 2xl:w-1/5 xl:w-1/4 lg:w-1/3 2xl:h-1/5 xl:h-1/4 rounded-2xl   ">
				<img className="w-full" src={data.newsImage} alt="" />
			</figure>

			<div className="2xl:w-4/5 xl:w-3/4 lg:w-2/3 2xl:h-4/5 xl:h-3/4 self-start pt-5 ">
				<h2 className="text-primary text-xl sm:text-2xl md:text-3xl font-bold pb-5">
					{data.newsTitle}
				</h2>
				<p
					className={`${
						isSmall ? "text-sm" : "text-xl"
					} text-justify text-white `}
				>
					{data.newsBody}
				</p>
			</div>

			<img
				className="absolute top-1/2 -z-10 -right-14 scale-50 "
				src={halfCircle}
				alt=" "
			/>
		</article>
	);
};

export default NewsCart;