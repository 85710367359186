import React from 'react'
import { useQuery } from "@tanstack/react-query";
import { getRequest } from '../services/service';

const ProvincialNews = (endpoint) => {
  const provinceQuery = useQuery({
   queryKey:['city' , endpoint],
   queryFn: () => getRequest(endpoint , {
      headers: {
         "Content-Type": "application/json",
         Accept: "application/json",
      },
   }),
   initialData:[]
  })
  return provinceQuery
}

export default ProvincialNews