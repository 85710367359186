import React, { useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { dateConvertor } from "../utility/dateConvertor";
import { useMediaQuery } from "react-responsive";
import { CardText } from "react-bootstrap";


export const NewsCarousel = ({ data,setTagData,settings, isLgScreen, tagData ,scrollRef } ) => {

	// const [clickedCards, setClickedCards] = useState({});
	const isSmall = useMediaQuery({query:"(max-width:530px)"})
	// const scrollRef = useRef(null)


	const fetchReview = async (id)=>{
		const res = await fetch(`${process.env.REACT_APP_BASE_URL}news/${id}`)
		// const data =await res.json()
		// console.log(data);	
	}
	

	
	const handleTagData = (code,visitCount,created_data, newsImage , newsBody , newsTitle , id  )=>{
		// if state is false set review fetch
		// console.log(' click handler', )
		if(!tagData[id]){
			// mutaion happen here 
			fetchReview(id)
		}
		// setTagData({code , visitCount , created_data:formattedDate , newsImage, newsBody, newsTitle , [id]:true })
		setTagData({code , visitCount , created_data , newsImage, newsBody, newsTitle , [id]:true })
		// console.log(' scroll ref',scrollRef )
		if (scrollRef.current ) {
			// console.log(' in the scroll ref logic', )
			scrollRef.current.scrollIntoView({ behavior: 'smooth' });
		}
	}

	
	
	return (
		<div className="w-full m-auto">
			<div className="mt-5 lg:mt-20 ">
				<Slider {...settings}>
					{data.map((item) => (
						<div key={item.id} className="   text-white  overflow-hidden ">
							<div className="group flex relative justify-center  pt-5 pb-10 ">
								<img
									src={item.image}
									className={` ${isSmall ? 'h-52 w-52' : 'h-72 w-72'} rounded-2xl group-hover:grayscale`}
									alt=""
								/>
								<div
									// to={"#"}//i don't know its needed or not 
									onClick={()=> handleTagData(item.code,item.visitCount,item.created_data , item.image , item.body , item.title , item.id)}
									className={`absolute invisible transition duration-300 ease-in-out top-5 rounded-2xl opacity-0 bg-shadowLayer text-white flex justify-center items-end text-[16px] pb-4 group-hover:opacity-100 group-hover:visible ${isSmall ? 'h-52 w-52' : 'h-72 w-72'} `}
								>
									{/* {isLgScreen && item.body} show text on image */}
								</div>
							</div>
							<div className="flex flex-col justify-center items-center p-2">
								<p className="lg:text-xl text-sm font-bold text-center ">
									{item.title}
								</p>
								{/* {!isLgScreen && (
									<p className="text-primary text-2xl">
										{item.body}
									</p>
								)} */}
							</div>
						</div>
					))}
				</Slider>
			</div>
		</div>
	);
};
