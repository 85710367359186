import React from "react";
import { ImSearch } from "react-icons/im";
import st from '../styles/components/search.module.css'

const searchContainerClass = `flex p-2  m-auto justify-center items-center border-primary border-[1px] rounded-2xl`;
const searchMainClass = `flex-grow bg-transparent active:border-none active:outline-none  outline-none text-white placeholder:text-primary border-none placeholder:text-[20px] `;

export const SearchBar = ({className}) => {
	return (
		<div className={`${searchContainerClass} ${className}`}>
			<input
				className={`${searchMainClass} focus:shadow-none  shadow-none w-full lg:text-3xl 2xl:text-3xl 2xl:placeholder:text-3xl placeholder:text-base ${st} `}
				placeholder="جستجو"
				type="search"
				name=""
				id=""
			/>
			<button className="">

				<ImSearch className="text-primary text-[20px] lg:text-3xl" />
			</button>
		</div>
	);
};
