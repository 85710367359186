import React, { useState } from "react";
import { IoMdArrowDropleft } from "react-icons/io";
import n1 from "../../assets/images/n1.png";
import n2 from "../../assets/images/n2.png";
import n3 from "../../assets/images/n3.png";
import n4 from "../../assets/images/n4.png";
import CategoriesGetData from "../../features/categories";
import loading from "../../assets/images/loading.gif";
import { useMediaQuery } from "react-responsive";
import Loading from "../../components/Loading";

export const Category = ({ className, setState, state, setCatTitle }) => {
	const isLargeScreen = useMediaQuery({ query: "(max-width:1024px)" })
	// const catQuery = CategoriesGetData("cat_test");
	const catQuery = CategoriesGetData("category"); //real ones




	// const [selectedImage, setSelectedImage] = useState(n1);

	const handleClick = (title, array) => {
		setState((prev) => ({ ...prev, title: title, filteredData: array }));
		// setSelectedImage(n4);
		setCatTitle(title);
		state && console.log(state);
		
	};

	// ######################################
	// console.log("🧶", catQuery.data);

	// ########################################
	return (
		<section className={`${className}`}>
			<div>
				<h2 className="text-primary text-center lg:text-right font-bold 2xl:text-4xl text-[28px] pb-5">
					دسته‌بندی اخبار
				</h2>
				<ul className="">
					{catQuery.isFetching ? (
						<Loading/>
					) : catQuery.isError ? (
						<p className="text-center pt-3 text-2xl text-white">
							Error:{" "}
							{catQuery.error.message ||
								"مشکلی در سرور رخ داده است "}
						</p>
					) : (
						// #################### small screens #####################
						isLargeScreen?(
							<div className="  flex flex-wrap justify-around lg:hidden">
								{catQuery.data?.data?.data.map((item) => (
									<button
										onClick={() =>handleClick(
											item?.name,
											// item?.image,
											item?.news
										)}
										className="border-[1px] m-2 border-solid border-primary text-primary p-2 rounded-xl hover:bg-primary hover:text-white transition-all ease-in hover:border-white "
									>
										{item.name}
									</button>
								))}
							</div>

							// large screens
						):catQuery.data?.data?.data.map((item) => (
							// catQuery.data?.data?.data.map((item) => (
							<li
								key={item.id}
								onClick={() =>
									handleClick(
										item?.name,
										// item?.image,
										item?.news
									)
								}
								className={`${
									state.title === `${item.name}`
										? "text-primary"
										: "text-white"
								} hover:text-primary flex justify-start items-center pb-1 text-xl 2xl:text-3xl 2xl:py-4 cursor-pointer `}
							>
								<IoMdArrowDropleft className="text-primary" />
								{item.name}
							</li>
						))
						
					)}
				</ul>
			</div>
			{/* <div className="w-20 h-20 bg-test1"> */}
			{!isLargeScreen && <div className="overflow-hidden rounded-2xl  h-[20%] lg:h-[250px] xl:h-[300px] 2xl:h-[350px]  w-[60%] lg:w-[55%] xl:w-[60%] 2xl:w-[60%] ">
				<img
					className=" grayscale bg-center bg-cover w-full h-full  "
					src={n1}
					alt=" "
				/>
			</div>}
			
			{/* </div> */}
		</section>
	);
};
