import axios from "axios";

const baseURL = `${process.env.REACT_APP_BASE_URL}`;
// const baseURL = `${process.env.REACT_APP_JSON_SERVER}`;
// const baseURL = "http://localhost:5003/";
// console.log("the base url", baseURL);

export const service = axios.create({
	baseURL: baseURL,
});

export const getRequest = async (endpoint, config = {}) => {
	try {
		// console.log("prepare to fetch... ");
		const response = service.get(endpoint, config);
		return response;
	} catch (error) {
		throw error;
	}
};

export const postRequest = async (endpoint, body , config={}) => {
	try {
		const response =await service.post(endpoint, body , config);
		if(response.status !== 200){
			throw new Error(`request fail with status code:${response.status}`)
		}
		return response.data
	} catch (error) {
		console.log(error);
		throw error;
	}
};

export const patchRequest = async (endpoint, item) => {
	try {
		return await service.patch(endpoint, item);
	} catch (error) {
		throw error;
	}
};

export const deleteRequest = async (endpoint, item) => {
	try {
		return await service.delete(endpoint, item);
	} catch (error) {
		throw error;
	}
};
