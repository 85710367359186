import React, { useState } from "react";
import cartimg from "../../assets/images/cart.png";
import st from '../../styles/responsive.module.css'

export const AboutCart = ({ className }) => {
   const [showFullText, setShowFullText] = useState(false);

   const longText = `لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با
   استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله
   در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد
   نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد،
   کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان
   جامعه و متخصصان را می طلبد، تا با نرم افزارها شناخت بیشتری را
   برای طراحان رایانه ای علی الخصوص طراحان خلاقی، و فرهنگ پیشرو در
   زبان فارسی ایجاد کرد، در این صورت می توان امید داشت که تمام و
   دشواری موجود در ارائه راهکارها، و شرایط سخت تایپ به پایان رسد`

   // const longText =' Lorem ipsum dolor sit.'

   const lines = longText.split("\n")
   const initialLines = lines.length >3 ? lines.slice(0,3) : lines;
   
	return (
		<article className={`${className} ${st.resAboutCart} flex justify-center  p-20 lg:gap-80 `}>
			<div className={` flex flex-col  justify-center w-3/4  text-justify`}>
				<h1 className="text-4xl py-7 font-bold text-primary">
					{" "}
					درباره فــــــــــرس
				</h1>
				<p className="text-white leading-10">
					{initialLines.join("\n")}
					{showFullText && (
						<p>{lines.slice(initialLines.length).join("\n")}</p>
					)}
				</p>
				{lines.length >= 3 && (
					<div>
						<button
							className="border-[1px] border-primary rounded text-primary px-2 mt-6"
							onClick={() => setShowFullText(!showFullText)}
						>
							{showFullText ? " مخفی شو " : "بیشتر بدانید"}
						</button>
					</div>
				)}
			</div>

			<figure className="overflow-hidden rounded-3xl lg:w-1/3 ">
				<img src={cartimg} alt=" " />
			</figure>
		</article>
	);
};
