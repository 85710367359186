import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import NewsRealData from "../../features/news";
import { MainContainer } from "../../layouts/main container/mainContainer";
import { BsCalendar3 } from "react-icons/bs";
import { BsMenuDown } from "react-icons/bs";
import { BsEyeFill } from "react-icons/bs";
import Loading from "../../components/Loading";
import { useMediaQuery } from "react-responsive";
import TagsComponent from "../news/TagsComponent";

const HotNews = () => {
	const location = useLocation();
	const hotPostId = location.pathname.split("/").slice(-1);
	const newsQuery = NewsRealData(`news/${+hotPostId[0]}`);
	const helperArray = [];
	const isSmall = useMediaQuery({ query: "(max-width:530px)" });


	// helpperArray.push(newsQuery.data.data)
	// console.log("📅", helpperArray);

	//  const newsId = match.params.newsId;
	//  const selectedNews = news.find((item) => item.id === newsId);

	//  if (!selectedNews) {
	//    return <div>News not found</div>;
	//  }

	// useEffect(() => {
	// 	const fetchReview = async (id)=>{
	// 		const res = await fetch(`${process.env.REACT_APP_BASE_URL}news/${id}`)	
	// 	}
	// }, []);
	
	
	


	return (
		<MainContainer>
			{/* <Header /> */}
			<section className="lg:mt-[283px] text-white">
				{newsQuery.isFetching ? (
					<Loading/>
				) : newsQuery.isError ? (
					"error something went wrong"
				) : (
					(helperArray.push(newsQuery.data?.data),
					// console.log(helpperArray),
					helperArray[0] &&
						helperArray.map((item) => (
							<>
								{/* ####################### tags ####################### */}
								<div className="mx-10 py-10 lg:px-20 ">
									<TagsComponent tagData={item} />
								</div>
								<article className="flex flex-col lg:flex-row justify-between relative items-center gap-10 py-10 md:py-20 px-10 md:px-[120px] ">
									<figure className="overflow-hidden w-3/5  sm:w-2/5 2xl:w-1/5 xl:w-1/4 lg:w-1/3 2xl:h-1/5 xl:h-1/4 rounded-2xl   ">
										<img
											className="w-full"
											src={item.url[0]}
											alt=""
										/>
									</figure>

									<div className="2xl:w-4/5 xl:w-3/4 lg:w-2/3 2xl:h-4/5 xl:h-3/4 self-start pt-5 ">
										<h2 className="text-primary text-xl sm:text-2xl md:text-3xl font-bold pb-5">
											{item.title}
										</h2>
										<p
											className={`${
												isSmall ? "text-sm" : "text-xl"
											} text-justify text-white `}
										>
											{item.body}
										</p>
									</div>

									{/* <img
									className="absolute top-1/2 -z-10 -right-14 scale-50 "
									// src={halfCircle}
									alt=" "
								/> */}
								</article>
								
							</>
						)))
				)}
			</section>
		</MainContainer>
	);
};

export default HotNews;
