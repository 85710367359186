import React from "react";
import { getRequest } from "../services/service";
import { useQuery } from "@tanstack/react-query";
import { useLocation } from "react-router-dom";

const NewsRealData = (endpoint) => {
	const location = useLocation(); // Get current route information
	const newsQuery = useQuery({
		queryKey: ["news" , endpoint],
		queryFn: () =>
			getRequest(endpoint, {
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
				},
			}),
			// enabled:location.pathname === "/" || location.pathname === '/news',
			initialData : []
	});
	return newsQuery;
};
export default NewsRealData;
