import React, { forwardRef } from "react";
import { BsCalendar3 } from "react-icons/bs";
import { BsMenuDown } from "react-icons/bs";
import { BsEyeFill } from "react-icons/bs";
import { dateConvertor } from "../../utility/dateConvertor";
import { ToastContainer ,toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const TagsComponent =forwardRef(({ tagData }, ref) => {
	
	// created_at use in provinceNews page and the created_data use in news page 
	// console.log("✔✈",tagData);
   const formattedDate = dateConvertor(tagData.created_data || tagData.created_at || '2023-07-24T17:22:43.000000Z')
	// console.log(' date',typeof formattedDate , formattedDate )


	
	const notify = () => toast.success("نظر شما ثبت شد");
   
	return (
		<section
			ref={ref}
			className="flex relative justify-between !items-center"
		>
			<div className=" flex justify-evenly flex-wrap lg:justify-start items-center lg:py-10 py-5 my-16 lg:m-0 gap-5 sm:flex-row flex-col border-primary border-[1px] rounded-3xl lg:border-none w-full ">
				<button className="text-white flex justify-between items-center border-[1px] border-primary rounded-3xl px-3 py-2 w-11/12 sm:w-max  ">
					<BsCalendar3 className="ml-1" />
					تقویم
					<span className="text-primary px-2 ">{ formattedDate === '1402/5/2' ?"...":formattedDate }</span>
					{/* <span className="text-primary px-2 ">teeest</span> */}
				</button>
				<button className="text-white flex justify-between items-center border-[1px] border-primary rounded-3xl px-3 py-2 w-11/12 sm:w-max ">
					<BsMenuDown className="ml-1" />
					کدخبر
					<span className="text-primary px-2 ">{tagData.code}</span>
				</button>
				<button className="text-white flex justify-between items-center border-[1px] border-primary rounded-3xl px-3 py-2 w-11/12 sm:w-max ">
					<BsEyeFill className="ml-1" />
					بازدید
					{tagData.id && (
						<span className="text-primary px-2 ">
							{tagData.visitCount}
						</span>
					)}
					{!tagData.id && (
						<span className="text-primary px-2 ">
							{+tagData.visitCount + 1}
						</span>
					)}
				</button>
				{/* this part gonna add when API ready */}
				{/* <button
					onClick={notify}
					className="text-white flex justify-center items-center border-[1px] border-primary rounded-3xl px-3 py-2 bg-primary w-11/12 sm:w-max "
				>
					ثبت‌نظر
				</button> */}
			</div>
			{/* <img
						src={ti}
						className="scale-50 absolute -left-52  filter brightness-50 "
						alt=""
					/> */}
			<ToastContainer
				position="bottom-left"
				autoClose={3000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme="light"
			/>
		</section>
	);
})

export default TagsComponent;
