import React, { useState } from "react";
import { FeedBackGetData } from "../../features/feedback";
import loading from "../../assets/images/loading.gif";

export const FeedbackSection = () => {
	const [showFullText1, setShowFullText1] = useState(false);
	const [showFullText2, setShowFullText2] = useState(false);
	const longText = `لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ،
   با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه
   و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی
   تکنولوژی مورد نیاز، و کاربردهای متنوع با استفاده از طراحان
   گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و
   سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز،
   و کاربردهای متنوع با استفاده از طراحان گرافیک است، چاپگرها و
   متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و
   برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع و با
   استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و
   مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی
   تکنولوژی مورد نیاز، و کاربردهای متنوع`;

	const feedQuery = FeedBackGetData("connection");
	// console.log(feedQuery.data?.data?.data);

	return (
		<section className="p-20">
			<h2 className=" text-primary text-2xl lg:text-4xl font-bold pb-10 text-center">
				نظرات مشتریان
			</h2>
			{feedQuery && feedQuery.isLoading ? (
				<figure className="w-full flex justify-center">
					<img width={50} height={50} src={loading} alt=" loading..." />
				</figure>
			) : feedQuery.isError ? (
				<p className="text-center pt-3 text-2xl text-white">
					{"مشکلی در دریافت دیتا پیش آمده آست  "}
				</p>
			) : (
				feedQuery.data?.data?.data.map((comment) => (
					<section key={comment.id} className="flex flex-col lg:flex-row justify-between items-center mb-4 gap-5 lg:gap-20 p-4 rounded-xl border-primary border-[1px]">
						<div className="flex flex-col lg:flex-row justify-center lg:justify-between items-center  lg:gap-10">
							<img
								className="w-h-20 h-20 rounded-xl"
								src="https://xsgames.co/randomusers/avatar.php?g=male"
								alt=" "
							/>
							<span className="text-white text-center lg:text-right font-bold min-w-32">
								{comment.firstname}  {comment.lastname}
							</span>
						</div>
						<p className="flex-grow text-white text-justify">
							{comment.body.split("\n")[0]}
							{showFullText1 && (
								<span>{comment.body.split("\n").slice(1).join("\n")}</span>
							)}
							<button
								onClick={() => setShowFullText1(!showFullText1)}
								className="text-primary"
							>
								{showFullText1 ? " ...   بستن" : "   ...بیشتر بخوانید"}
							</button>
						</p>
					</section>
				))
			)}

			{/* <section className="flex flex-col lg:flex-row justify-between items-center mb-4 gap-5 lg:gap-20 p-4 rounded-xl border-primary border-[1px]">
				<div className="flex flex-col lg:flex-row justify-center lg:justify-between items-center  lg:gap-10">
					<img
						className="w-h-20 h-20 rounded-xl"
						src="https://xsgames.co/randomusers/avatar.php?g=female"
						alt=" "
					/>
					<span className="text-white text-center lg:text-right font-bold min-w-32">
						 کیمیا کاشانی  
					</span>
				</div>
				<p className="flex-grow text-white text-justify">
					{longText.split("\n")[0]}
					{showFullText2 && (
						<span>{longText.split("\n").slice(1).join("\n")}</span>
					)}
					<button
						onClick={() => setShowFullText2(!showFullText2)}
						className="text-primary"
					>
						{showFullText2 ? " ...   بستن" : "   ...بیشتر بخوانید"}
					</button>
				</p>
			</section> */}
		</section>
	);
};
