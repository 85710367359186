import { configureStore } from "@reduxjs/toolkit";
// import {apiSlice} from "./api/mySliceName"



export const store = configureStore({
   reducer:{
      // Add your reducers here
      // api:apiSLice.reducer,
   }
})