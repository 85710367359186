import { useQuery } from "@tanstack/react-query";
// import React from "react";
import { getRequest } from "../services/service";
// import { useLocation } from "react-router-dom";

const CategoriesGetData = (endpoint) => {
	// const location = useLocation(); // Get current route information
	const catQuery = useQuery({
		queryKey: ["cat" , endpoint],
		queryFn: () =>
			getRequest(endpoint, {
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
				},
			}),
		// enabled:location.pathname === "/"|| location.pathname === "/news",
		initialData:[]
	});
	return catQuery;
};
export default CategoriesGetData;