import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import tv1 from "../../assets/images/tv1.png";
import { useMediaQuery } from "react-responsive";
import { IoEyeOutline } from "react-icons/io5";
import Loading from "../../components/Loading";

export const TvSection = () => {
	const isLargeScreen = useMediaQuery({ query: "(max-width:1024px)" });
	const isMedScreen = useMediaQuery({ query: "(max-width:770px)" });
	const isSmallScreen = useMediaQuery({ query: "(max-width:640px)" });

	const fakedata = [
		{
			id: 1,
			href: "#",
			image: tv1,
			title: "مسابقات سهند",
			date: "دو روز پیش",
			review: 521,
		},
		{
			id: 2,
			href: "#",
			image: tv1,
			title: "مسابقات دماوند",
			date: "سه روز پیش",
			review: 450,
		},
		{
			id: 3,
			href: "#",
			image: tv1,
			title: "مسابقات سیمرغ",
			date: "1402/02/03",
			review: 127,
		},
		{
			id: 4,
			href: "#",
			image: tv1,
			title: "مسابقات hct",
			date: "1402/01/25  ",
			review: 152,
		},
	];

	return (
		<section className="lg:p-20 lg:py-20  ">
			{!isLargeScreen ? (
				<h2 className=" text-primary text-4xl font-bold pb-10 text-center">
					فرس تی‌وی
				</h2>
			) : (
				// -------------- small screens -------------------------
				<div className="flex justify-between px-10 md:px-20 pb-20 text-primary  ">
					<span className=" text-2xl lg:text-4xl font-bold ">
						فرس تی‌وی
					</span>
					<NavLink
						to={"/"}
						className="border-[1px] border-primary rounded-xl text-primary px-3 py-1"
					>
						بازدید بیشتر
					</NavLink>
				</div>
			)}
			<section className="flex  justify-between gap-20 px-20">
				{!isLargeScreen
					? fakedata.slice(0, 3).map((item) => (
							<div className="flex flex-col " key={item.id}>
								<NavLink className="group relative">
									<img
										src={item.image}
										className="w-full object-cover rounded-2xl "
										alt=""
									/>
									<span className="transition duration-300 ease-in-out absolute top-0 w-full h-[100%] opacity-0 bg-shadowLayer group-hover:opacity-100 text-white flex justify-center items-center flex flex-col " >در حال آماده سازی <Loading/></span>
								</NavLink>
								<div className="flex justify-between px-1 pt-10">
									<span className="text-white text-sm 2xl:text-lg">
										<h3 className="font-bold"> {item.title}</h3>
										<p> {item.date} </p>
									</span>
									<span className="text-primary text-sm 2xl:text-lg">
										بازدید {item.review}
									</span>
								</div>
							</div>
					   ))
					: isSmallScreen
					? fakedata.slice(0, 1).map((item) => (
							<div className="w-3/4 m-auto" key={item.id}>
								<NavLink className="group relative">
									<img
										src={item.image}
										className="w-full object-cover rounded-2xl "
										alt=""
									/>
									<span className="transition duration-300 ease-in-out absolute top-0 w-full h-[100%] opacity-0 bg-shadowLayer group-hover:opacity-100 text-white flex justify-center items-center flex flex-col" >در حال آماده سازی <Loading/> </span>
								</NavLink>
								<div className={` flex-col flex items-center justify-center px-1 pt-10`} >
									<span className="text-white  text-lg text-center">
										<h3
											className={`font-bold `}
										>
											{" "}
											{item.title}
										</h3>
										<p> {item.date} </p>
									</span>
									<span
										className={`text-primary flex gap-1 items-center  ${
											isMedScreen ? "text-xl" : "text-lg"
										}`}
									>
										<IoEyeOutline /> {item.review}
									</span>
								</div>
							</div>
					   ))
					: fakedata.slice(0, 2).map((item) => (
							<div className="w-1/2" key={item.id}>
								<NavLink className="group relative">
									<img
										src={item.image}
										className="w-full object-cover rounded-2xl "
										alt=""
									/>
									<span className="transition duration-300 ease-in-out absolute top-0 w-full h-[100%] opacity-0 bg-shadowLayer group-hover:opacity-100 text-white flex justify-center items-center flex-col" >در حال آماده سازی <Loading/>
									</span>
								</NavLink>
								<div className="flex justify-between px-1 pt-10">
									<span className="text-white text-md">
										<h3
											className={`font-bold `}
										>
											{" "}
											{item.title}
										</h3>
										<p> {item.date} </p>
									</span>
									<span
										className={`text-primary flex gap-1 items-center  ${
											isMedScreen ? "text-xl" : "text-lg"
										}`}
									>
										<IoEyeOutline /> {item.review}
									</span>
								</div>
							</div>
					   ))}
			</section>

			{!isLargeScreen && (
				<div className="flex justify-center p-10">
					<NavLink
						to={"/"}
						className="border-[1px] border-primary rounded-xl text-primary px-3 py-1"
					>
						بازدید بیشتر
					</NavLink>
				</div>
			)}
		</section>
	);
};
