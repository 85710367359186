import moment from 'jalali-moment';


export const dateConvertor = (dateString) => {
   
   // console.log("🏮",dateString );
   //dateString is ==> 2024-07-24T17:22:43.000000Z
   // const year = parseInt(dateString.substring(0,4));
   // const month = parseInt(dateString.substring(5,7))-1;
   // const day = parseInt(dateString.substring(8,10));

   const solarDate = moment(dateString ,'YYYY-MM-DDTHH:mm:ss.SSSSZ').local('fa').format('jYYYY/jM/jD')
   // const gregorianDate = new Date(year,month,day).toLocaleDateString()

   return solarDate

   // moment.locale('fa');
   // let m = moment.from(`${year}/${month}/${day}`, 'en', 'YYYY-MM-DD').format();
   // const jYear = parseInt(m.substring(0,4))
   // const jMonth = parseInt(m.substring(5,7))-1
   // const jDay = parseInt(m.substring(8,10))
   
   // const solarDate = moment(`${jYear}-${jMonth}-${jDay}`).format('jYYYY/jMM/jDD')
   
   
   
   // const x = moment(dateString ,'YYYY/MM/DD').locale('fa').format('YYYY/MM/DD') 
   // const solarDate = new Intl.DateTimeFormat('fa-IR').format(d)
   // // const solarDate = moment(d).format('jYYYY/jMM/jDD');
   
   // // const formattedGregorianDate = gregorianDate.toLocaleDateString()
   


   // console.log("👽", jalaliDate );

   //-------------------------------------------------------------------------

   // -----------------------------------------------------------------------

   
   // return gregorian

   // const [year,month,day] = dateString.split('-').map(Number)
   // const formattedDate = new Date(year, month - 1, day).toLocaleDateString();

   // return formattedDate
};
