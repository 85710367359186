import { useMutation, useQuery } from "@tanstack/react-query";
import { getRequest, postRequest } from "../services/service";

const FeedBackGetData = (endpoint) => {
	const feedbackQuery = useQuery({
		queryKey: ["feed", endpoint],
		queryFn: () =>
			getRequest(endpoint, {
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
				},
			}),
		initialData: [],
	});
	return feedbackQuery;
};
export { FeedBackGetData };

// #@#@#@#@#@#@#@#@#@#@#@#@#@#@#@#@#@#@#@#@#@#@#@#@# post @#@#@#@#@#@#@#@#@#@#@#@#@#@#@#@#@#@#@#@#@#@#@#@#@#@#@#@

const FeedBackPostData = (endpoint, body ) => {
	const feedbackMutation = useMutation({
		mutationKey: "postFeed",
		mutationFn: () =>
			postRequest(endpoint, body, {
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
				},
			}),
		// onSuccess: (data) => {
			// console.log("✅✔✅ Form submitted successfully:", data);
			// setFromData({ firstname:"" , lastname:"",mobile:"",email:"",body:""})
		// },
		onError: (data) => {
					console.log("❌", data);
		},
	});
   return feedbackMutation
};
export {FeedBackPostData}
