import React from "react";
import { NavLink, useMatch } from "react-router-dom";
import { BsEnvelopeFill } from "react-icons/bs";
import { BsFillTelephoneFill } from "react-icons/bs";
import { ImLocation } from "react-icons/im";
import logo from "../../assets/images/Logo_transparent.png";
import { useMediaQuery } from "react-responsive";

export const Footer = () => {
	const isLargeScreen = useMediaQuery({ query: "(min-width:1024px)" });
	const isSmall = useMediaQuery({ query: "(max-width:530px)" });

	return (
		<section className="lg:bg-[url('./assets/images/footer.jpg')] w-100  bg-no-repeat bg-cover text-white text-[20px] flex flex-col lg:gap-24">
			<div className="w-[100%] lg:bg-[#00000099] lg:py-44 lg:px-20">
				<div className="flex m-auto flex-col lg:items-start lg:gap-20 w-[95%] gap-5 pb-20   ">
					{isLargeScreen ? (
						<>
							<section className="flex justify-between items-start w-full gap-10">
								<div className="flex flex-col items-center ">
									<h3 className="text-primary font-bold pb-3">
										جشنواره ها
									</h3>
									<NavLink to={"/"}>مسابقات پرش</NavLink>
									<NavLink to={"/"}>هنر های رزمی</NavLink>
									<NavLink to={"/"}>پونی کلاب</NavLink>
								</div>
								<div className=" flex flex-col items-center">
									<h3 className="text-primary font-bold pb-3">
										لینک های مفید
									</h3>
									<span className="flex gap-1">
										<NavLink to={"/news"}>اخبار</NavLink>|
										<NavLink to={"/"}>بلاگ</NavLink>|
										<NavLink to={"/"}>درباره‌ما</NavLink>|
										<NavLink to={"/"}>ثبت‌نام</NavLink>
									</span>
								</div>
								<div className="flex flex-col items-center">
									<h3 className="text-primary font-bold pb-3">
										ازطریق پل های ارتباطی زیر می‌توانید باما
										درارتباط باشید
									</h3>
									<div className="">
										<p className="flex justify-start gap-4 items-center">
											<ImLocation />
											<NavLink to={"/"}>
												ایران -مشهد- میدان ده دی
											</NavLink>{" "}
										</p>
										<p className="flex justify-start gap-4 items-center">
											<BsFillTelephoneFill />
											<NavLink to={"/"}>
												09390580518
											</NavLink>
										</p>
										<p className="flex justify-start gap-4 items-center">
											<BsEnvelopeFill />
											<NavLink to={"/"}>
												info@farasco.ir
											</NavLink>
										</p>
									</div>
								</div>
							</section>
							<div className="flex items-center justify-start">
								<NavLink to={"/"}>
									<img
										src={logo}
										className="scale-50"
										alt=" "
									/>
								</NavLink>
								<p>
									کلیه حقوق این وب‌سایت متعلق به{" "}
									<strong>گروه رسانه‌ای فرس </strong>می‌باشد
								</p>
							</div>
						</>
					) : (
						<>
							<NavLink className="flex justify-center" to={"/"}>
								<img src={logo} className="scale-50" alt=" " />
							</NavLink>
							<p
								className={`${
									isSmall ? "text-base" : ""
								} text-center text-primary font-bold`}
							>
								از طریق پل ‌های ارتباطی زیر میتوانید با ما در
								ارتباط باشید
							</p>
							<div
								className={`${
									isSmall ? "flex-col gap-5" : "gap-10"
								} flex justify-center text-sm sm:text-sm md:text-base lg:text-lg  `}
							>
								<p className="flex justify-center gap-4 items-center">
									<ImLocation />
									<NavLink to={"/"}>
										ایران -مشهد- میدان ده دی
									</NavLink>{" "}
								</p>
								<p className="flex justify-center gap-4 items-center">
									<BsFillTelephoneFill />
									<NavLink to={"/"}>09390580518</NavLink>
								</p>
								<p className="flex justify-center gap-4 items-center">
									<BsEnvelopeFill />
									<NavLink to={"/"}>info@farasco.ir</NavLink>
								</p>
							</div>

							<p className="text-center text-primary text-xl font-bold">
								لینک‌های مفید{" "}
							</p>

							<div className="flex justify-center text-lg gap-1">
								<NavLink to={"/news"}>اخبار</NavLink>|
								<NavLink to={"/"}>بلاگ</NavLink>|
								<NavLink to={"/"}>درباره‌ما</NavLink>|
								<NavLink to={"/"}>ثبت‌نام</NavLink>
							</div>
							<p className="text-center text-base">
								کلیه حقوق این وب سایت متعلق به گروه رسانه ای فرس
								می باشد
							</p>
						</>
					)}
				</div>
			</div>
		</section>
	);
};
