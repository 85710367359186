import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { DevTool } from "@hookform/devtools";
import { FeedBackPostData } from "../features/feedback";

export const ContactForm = () => {
	const [formData, setFromData] = useState({
		firstname: "",
		lastname: "",
		mobile: "",
		email: "",
		body: "",
	});

	const form = useForm({ mode: "onBlur" });
	const {
		control,
		reset,
		register,
		handleSubmit,
		formState: { errors, isSubmitSuccessful, isDirty, isValid },
	} = form;

	// clear the form
	useEffect(() => {
		console.log(isSubmitSuccessful);
		if (isSubmitSuccessful) {
			reset();
		}
	}, [isSubmitSuccessful]);


	const feedbackMutation = FeedBackPostData("connection/store", formData);

	const submit = (d) => {
		// console.log("this 🎇", d);
		// console.log("📅📅📅",formData);
		setFromData({
			...formData,
			firstname: d.firstname,
			lastname: d.lastname,
			mobile: d.mobile,
			email: d.email,
			body: d.body,
		});
		// console.log(formData);
		feedbackMutation.mutate(d);
	};

	return (
		<section className="px-10 py-20 sm:p-20">
			<h2 className=" text-primary text-xl lg:text-4xl font-bold pb-10 text-center">
				ارتباط باما
			</h2>
			{/* <form  onSubmit={(event) => handleSubmit(event)}> */}
			<form noValidate onSubmit={handleSubmit(submit)}>
				<section className=" grid grid-cols-1 lg:grid-cols-2 lg:grid-rows-3 gap-5 lg:gap-x-5">

					{/* name @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ */}
					<div className="">
						<input
							{...register("firstname", {
								required: true,
								maxLength: 30,
							})}
							placeholder="نام"
							className="w-full rounded-2xl border-primary border-[1px] bg-transparent placeholder:text-white text-white p-5 text-2xl  "
						/>
						{errors.firstname && (
							<span className="text-primary pr-2 text-sm lg:text-base">
								{" "}
								این فیلد الزامی است
							</span>
						)}
					</div>
					
					{/* last name @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ */}

					<div>
						<input
							{...register("lastname", {
								required: true,
								maxLength: 50,
							})}
							placeholder="نام خانوادگی"
							className="rounded-2xl border-primary border-[1px] bg-transparent placeholder:text-white text-white p-5 text-2xl w-full "
						/>
						{errors.lastname && (
							<span className="text-primary pr-2 text-sm lg:text-base">
								{" "}
								این فیلد الزامی است
							</span>
						)}
					</div>
					{/* mobile @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ */}
					<div>
						<input
							{...register("mobile", {
								required: "این فیلد الزامی است",
								pattern: {
									value: /^0\d{10}$/,
									message:
										"شماره باید با صفر شروع و 11 رقم باشد",
								},
							})}
							type="number"
							dir="rtl"
							placeholder="تلفن"
							className=" rounded-2xl  border-primary border-[1px] bg-transparent placeholder:text-white text-white p-5 text-2xl w-full "
						/>
						{errors.mobile && (
							<span className="text-primary pr-2 text-sm lg:text-base">
								{errors.mobile?.message}
							</span>
						)}
					</div>

					{/* email res @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ */}
					<div className="lg:hidden">
						<input
							{...register("email", {
								pattern: {
									value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
									message: "فرمت نامعتبر ",
								},
							})}
							placeholder=" ایمیل (اختیاری)"
							className=" rounded-2xl border-primary border-[1px] bg-transparent placeholder:text-white text-white p-5 text-2xl w-full "
						/>
						{errors.email && (
							<span className="text-primary pr-2 text-sm lg:text-base">
								{errors.email?.message}
							</span>
						)}
					</div>

					{/* comments @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ */}

					<div className="row-span-2">
						<textarea
							{...register("body", { required: true })}
							placeholder="نظرات خود را در این کادر بنویسید."
							className="rounded-2xl border-primary border-[1px] bg-transparent placeholder:text-white h-[100%]  text-white p-5 text-2xl w-full"
						/>
						{errors.body && (
							<span className="text-primary pr-2 text-sm lg:text-base">
								{" "}
								این فیلد الزامی است
							</span>
						)}
					</div>

					{/* email @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ */}
					<div>
						<input
							{...register("email", {
								pattern: {
									value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
									message: "فرمت نامعتبر ",
								},
							})}
							type="email"
							placeholder="ایمیل(اختیاری)"
							className="rounded-2xl hidden lg:!block border-primary border-[1px] bg-transparent placeholder:text-white text-white p-5 text-2xl w-full "
						/>
						{errors.email && (
							<span className="text-primary pr-2 text-sm lg:text-base">
								{errors.email?.message}
							</span>
						)}
					</div>

					{/* 
					@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ */}
				</section>

				<div className="text-center py-3">
					<button
						disabled={!isDirty || !isValid}
						type="submit"
						className="border-primary border-[1px] rounded-2xl text-primary p-3 mt-7 font-bold"
					>
						{feedbackMutation.isPending
							? "در‌حال‌ارسال"
							: "ثبت نظر"}
					</button>
				</div>
			</form>
			<DevTool control={control} />
		</section>
	);
};
