import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { PublicRoute } from "./publicRoute";
import Home from "../pages/home/Home";
import News from "../pages/news/News";
import HotNews from "../pages/hot posts/HotNews";
import ProvinceNews from "../pages/province news/ProvinceNews";
import ComingSoon from "../pages/coming soon/ComingSoon";

export const AppRoute = () => {
	return (
		<BrowserRouter>
			<Routes>
				<Route element={<PublicRoute />}>
					<Route path="/" element={<Home />} />
					<Route path="/news" element={<News />} />
					<Route path="/news/:newsId" element={<HotNews/>} />
					<Route path="/news/province/:id" element={<ProvinceNews/>} />
					<Route path="/comingsoon" element={<ComingSoon/>} />
				</Route>
			</Routes>
		</BrowserRouter>
	);
};
