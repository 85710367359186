import React from "react";
import glp1 from "../../assets/images/glp1.png";
import glp2 from "../../assets/images/glp2.png";
import glp3 from "../../assets/images/glp3.png";
import n3 from "../../assets/images/n3.png";
import n4 from "../../assets/images/n4.png";
import { Carousel } from "../../components/Carousel";
import { useMediaQuery } from "react-responsive";

const fakeData = [
	{
		id: 1,
		im: glp1,
		title: "title 1",
		aothor: "نوشته شده توسط digiyato team",
		date: "1402/12/12",
	},
	{
		id: 2,
		im: glp2,
		title: "title 2",
		aothor: "نوشته شده توسط digiyato team",
		date: "1402/12/12",
	},
	{
		id: 3,
		im: glp3,
		title: "title 3",
		aothor: "نوشته شده توسط digiyato team",
		date: "1402/12/12",
	},
	{
		id: 4,
		im: n3,
		title: "title 4",
		aothor: "نوشته شده توسط digiyato team",
		date: "1402/12/12",
	},
	{
		id: 5,
		im: n4,
		title: "title 5",
		aothor: "نوشته شده توسط digiyato team",
		date: "1402/12/12",
	},
];

export const BlogSection = () => {
	const isMedScreen = useMediaQuery({ query: "(max-width:768px)" });
	const isSmallScreen = useMediaQuery({ query: "(max-width:640px)" });
	const settings = {
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: isMedScreen ?isSmallScreen?1:2 :3  ,
		slidesToScroll: 1,
	};

	return (
		<section className="p-20">
			<h2 className="text-primary text-2xl lg:text-4xl font-bold">بلاگ</h2>
			<div className="w-full flex justify-center p-3">
				<Carousel data={fakeData} settings={settings} />
			</div>
		</section>
	);
};
