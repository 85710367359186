import React from "react";
import { useMediaQuery } from "react-responsive";
import { useLocation } from "react-router-dom";
import { MainContainer } from "../../layouts/main container/mainContainer";
import TagsComponent from "../news/TagsComponent";

const ProvinceNews = () => {
   const location = useLocation();
	// const postId = location.pathname.split("/").slice(-1);
   const isSmall = useMediaQuery({ query: "(max-width:530px)" });
   const passedData = JSON.parse(sessionStorage.getItem('province_data')) 
   console.log(' ❤️❤️❤️', passedData )
	return (
      <MainContainer>
      {/* <Header /> */}
      <section className="lg:mt-[283px] text-white">
         <div className="mx-10 py-10 lg:px-20 ">
            <TagsComponent tagData={passedData}/>
            <h2 className="text-primary text-xl sm:text-2xl md:text-3xl font-bold pb-5">{passedData.title}</h2>
            <p className=" text-md sm:text-xl   pb-5">{passedData.body}</p>
         </div>
       
      </section>
   </MainContainer>
   );
};

export default ProvinceNews;
