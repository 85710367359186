import React from "react";
import { ImSearch } from "react-icons/im";
import st from "../../styles/responsive.module.css";
import { SearchBar } from "../../components/Searchbar";

export const Banner = () => {
	return (
		<section
			className={` w-11/12 lg:h-[90vh] m-auto lg:relative lg:bg-[#00000099] lg:mt-14 flex flex-col justify-between`}
		>
			<div className="text-white lg:absolute w-full h-full z-10 flex items-center justify-center    ">
				<div className="flex flex-col gap-5 mt-10 lg:mt-20">
					<p
						className={`${st.farasMainTitle} text-3xl md:text-4xl lg:text-[40px] text-primary font-extrabold `}
					>
						<span
							// style={{ fontSize: "calc(1.5rem ,3vw,3rem )" }}
							className="block sm:inline text-xl text-center sm:text-3xl 2xl:text-6xl  lg:text-4xl xl:text-5xl "
						>
							گروه رسانه‌ای{" "}
						</span>
						<span className="block sm:inline text-4xl lg:text-4xl xl:text-5xl 2xl:text-6xl  text-center py-5">
							فــــــــــرس
						</span>
					</p>
					<SearchBar />
					<div className=" invisible lg:flex lg:visible justify-center">
						<a href="#newsSection" className="absolute bottom-1">
							<div className="w-[30px] h-[50px] rounded-3xl border-[3px] border-secondary flex justify-center items-start">
								<div className="w-4 h-4 rounded-full bg-primary mb-5 animate-bounceY" />
							</div>
						</a>
					</div>
				</div>
			</div>
		</section>
	);
};
