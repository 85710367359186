import React from "react";

import { NavLink } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useMediaQuery } from "react-responsive";

export const Carousel = ({ data , settings }) => {
	const isLargeScreen = useMediaQuery({query:'(min-width:1024px)'})
	const isMedScreen =  useMediaQuery({query:'(min-width:768px)'})

	// console.log(isLargeScreen);
	
	

	return (
		<div className="w-full m-auto">
			<div className="lg:mt-20 ">
				<Slider {...settings}>
					{data.map((item) => (
						<div className="   text-white  overflow-hidden ">
							<div className=" flex justify-center items-center pt-5 pb-10 relative">
								<img
									src={item.im}
									className="w-52 h-52 lg:h-72 lg:w-72 rounded-2xl"
									alt=""
								/>
								{!isLargeScreen && (
									<NavLink
										to={"/news"}
										className="absolute bottom-12  flex flex-col justify-center items-center w-[90%] text-center"
									>
										<p className="text-lg font-bold">{item.title}</p>
										<p className="text-sm">{item.aothor}</p>
										<p className="text-sm">{item.date}</p>
									</NavLink>
								)}
							</div>
							{isLargeScreen && (
								<NavLink
									to={"/news"}
									className="flex flex-col justify-center items-center p-2"
								>
									<p className="text-xl font-bold">{item.title}</p>
									<p>{item.aothor}</p>
									<p>{item.date}</p>
								</NavLink>
							)}
						</div>
					))}
				</Slider>
			</div>
		</div>
	);
};
