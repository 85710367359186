import React from "react";
import { Footer } from "../footer/Footer";
import { Header } from "../header/Header";

export const MainContainer = ({ children }) => {
	return (
		<>
			<Header/>
				<main className="">{children}</main>
			<Footer />
		</>
	);
};
