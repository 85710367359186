import React from "react";
import { Header } from "../../layouts/header/Header";
import i1 from "../../assets/images/cm2.png";
import { useMediaQuery } from "react-responsive";

const ComingSoon = () => {

   const largeView  = useMediaQuery({ query: "(min-width:1024px)" })

	return (
		<>
			<Header />
			<div className="relative flex flex-col justify-between items-end h-[100vh] ">
            <div className="relative w-full  basis-[50%]"> 
               <div className="bg-primary bg-opacity-70 z-10 absolute lg:-bottom-40 bottom-1/2 left-1/2 transform -translate-x-1/2 translate-y-40 lg:-translate-y-10  " style={{ borderRadius: "90% 15% 90% 15% / 15% 90% 15% 90%" }}
               >
                  {largeView ? (
                     <img
                        className="hover:scale-110  transition-all duration-200 animate-scaleChanges"
                        src={i1}
                        width={300}
                        height={300}
                        alt=" "
                        loading="lazy"
                     />
                  ) : (
                     <img
                        className="hover:scale-110  transition-all duration-200 animate-scaleChanges"
                        src={i1}
                        width={200}
                        height={200}
                        alt=" "
                        loading="lazy"
                     />
                  )}
               </div>
            </div>

            
				<div
					className="relative w-screen basis-[50%] invisible  lg:visible lg:flex justify-end items-end  bg-contain bg-repeat-x "
					style={{
						backgroundImage:
							"url('http://s18.postimg.io/49u99igkp/field_008.jpg')",
						borderRadius: "  76% 24% 0% 100% / 100% 30% 70% 0%",
					}}
				>
					<img
						className=" !h-60 focus:outline-none animate-runningHorse pb-10"
						tabindex="1"
						src="http://cliparts.co/cliparts/8Tz/Byj/8TzByjyTp.gif"
						alt="Running Horse"
					/>
				</div>
			</div>
		</>
	);
};

export default ComingSoon;


//           focus:animate-runningHorse 
// 76% 24% 0% 100% / 100% 30% 70% 0%
// 74% 26% 0% 100% / 100% 0% 100% 0%