import React, { useEffect, useRef, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import logo from "../assets/images/Logo_transparent.png";
import st from "../styles/components/menu.module.css";
import { FaUser } from "react-icons/fa";
import { FaUserCheck } from "react-icons/fa";
import { BsTicketPerforatedFill } from "react-icons/bs";
import { RxHamburgerMenu } from "react-icons/rx";
import { useMediaQuery } from "react-responsive";
import { RxCross1 } from "react-icons/rx";
import chup from "../assets/images/icons8-chevron-50.png";
import chdown from "../assets/images/icons8-chevron-50-down (1).png";

export const Menu = ({ className }) => {
	const data = [
		{
			title: "صفحه اصلی",
			href: "/",
		},
		{
			title: "گالری تصاویر",
			href: "/comingsoon",
		},
		{
			title: "فرس تی‌وی",
			href: "/comingsoon",
		},
		{
			title: "اخبار",
			href: "/news",
		},
		{
			title: "بلاگ",
			href: "/comingsoon",
		},
		{
			title: "درباره ما",
			href: "#about",
		},
		{
			title: "ارتباط با ما",
			href: "#contact",
		},
	];

	const [isOpen, setIsOpen] = useState({menu: false,prof: false,});
	const [profileHover, setProfileHover] = useState(false);
	const [userCheckHover, setUserCheckHover] = useState(false);
	const [fileBreakHover, setFileBreakHover] = useState(false);
	const isResponsive = useMediaQuery({ query: "(min-width:1024px)" });
	const isSmall = useMediaQuery({ query: "(max-width:640px)" });
	const menuRef = useRef(null);
	const profileRef = useRef(null);
	const navigate = useNavigate()


	const handleMenuClick = (hash)=>{
		console.log("😀");
		navigate(`/${hash}`)

	}

	useEffect(() => {
		const handleClickHamburgerMenu = (event) => {
			if (
				isOpen.menu &&
				menuRef.current &&
				!menuRef.current.contains(event.target)
			)
				setIsOpen((prev) => ({ ...prev, menu: false }));
			if (
				isOpen.prof &&
				profileRef.current &&
				!profileRef.current.contains(event.target)
			) {
				setIsOpen((prev) => ({ ...prev, prof: false }));
			}
		};
		document.addEventListener("click", handleClickHamburgerMenu);

		return () => {
			document.removeEventListener("click", handleClickHamburgerMenu);
		};
	}, [isOpen]);

	return (
		<nav
			className={` ${className} flex lg:items-start bg-shadowLayer relative justify-between `}
		>
			{isResponsive && (
				<>
					<div
						className={`${st.logoNavContainer}  lg:flex lg:items-center lg:justify-center lg:gap-2 `}
					>
						{/****************************** logo ******************************/}

						<Link to="/" className="">
							<img
								src={logo}
								className=" p-1 aspect-square object-contain mix-blend-screen"
								alt=""
							/>
						</Link>

						{/* menu lists */}

						<ul className={` lg:flex gap-12 bg-transparent ${st.ui}`}>
							{data.map((item, index) => (
								<li className="xl:text-3xl" key={index}>
									{item.href === "#about" ||
									item.href === "#contact" ? (
										<span
											onClick={() => handleMenuClick(item.href)} // Navigate with hash
											className="xl:text-2xl cursor-pointer"
										>
											{item.title}
										</span>
									) : (
										<NavLink
											to={item.href}
											className={({ isActive }) =>
												isActive
													? "active xl:text-2xl"
													: "xl:text-2xl"
											}
										>
											{item.title}
										</NavLink>
									)}
								</li>
							))}
						</ul>
					</div>
				</>
			)}

			{!isResponsive && (
				<>
					<button
						onClick={(e) => {
							e.stopPropagation();
							setIsOpen((prev) => ({
								...prev,
								menu: !prev.menu,
							}));
						}}
						className={` sm:basis-[20%] text-white text-3xl `}
					>
						{isOpen.menu ? (
							<RxCross1 className="text white" />
						) : (
							<RxHamburgerMenu className="text white" />
						)}
					</button>

					<div
						ref={menuRef}
						className={`${
							st.backDrop
						} bg-[#00000099] rounded-bl-3xl backdrop:blur-sm absolute  top-[100px] right-0 z-40 w-48 h-[40vh]  ${
							isOpen.menu
								? "transform -translate-x-0 transition duration-500 ease-in-out"
								: "transform translate-x-48 transition duration-500 ease-in-out"
						} `}
					>
						<ul className="flex flex-col items px-10 justify-between h-full py-5">
							{data.map((item) => (
								<li className="text-white hover:text-primary hover:transition hover:ease hover:duration-300">
									<NavLink
										to={item.href}
										className={({ isActive }) =>
											isActive ? "active" : ""
										}
									>
										{item.title}
									</NavLink>
								</li>
							))}
						</ul>
					</div>

					<NavLink className=" " to={"/"}>
						<img
							src={logo}
							width={100}
							className="p-1 aspect-square object-contain mix-blend-screen"
							alt=""
						/>
					</NavLink>
				</>
			)}
			{/* in case of responsive end  */}

			{isSmall ? (
				<>
					<button
						onClick={(e) => {
							e.stopPropagation();
							setIsOpen((prev) => ({
								...prev,
								prof: !prev.prof,
							}));
						}}
						className={` sm:basis-[20%] text-white text-3xl `}
					>
						{isOpen.prof ? (
							// <RxCross1 className="text white" />
							<img src={chup} className="!w-[35px] !h-[35px]" alt="" />
						) : (
							// <RxHamburgerMenu className="text white" />
							<img src={chdown} className="!w-[35px] !h-[35px]" alt="" />
						)}
					</button>
					<div
						ref={profileRef}
						className={`${
							st.backDrop
						} bg-[#00000099] rounded-br-3xl backdrop:blur-sm absolute  top-[-180px] left-0 -z-1 w-max h-[20vh]  ${
							isOpen.prof
								? "transform translate-y-[280px] transition duration-500 ease-in-out"
								: "transform translate-y-0 transition duration-500 ease-in-out"
						} `}
					>
						<ul className="flex flex-col items-end px-5 justify-between h-full w-max py-5">
							<NavLink
								to="/"
								onMouseEnter={() => setProfileHover(true)}
								onMouseLeave={() => setProfileHover(false)}
								className={`${st.linkBox} hover:gap-3 text-[15px] border-[1px] border-white p-2 rounded-full `}
							>
								{profileHover && (
									<span className=" text-primary mr-2">پروفایل</span>
								)}
								<FaUser className="text-primary" />
							</NavLink>

							<NavLink
								to="#"
								onMouseEnter={() => setUserCheckHover(true)}
								onMouseLeave={() => setUserCheckHover(false)}
								className={`${st.linkBox} hover:gap-3  text-[15px] border-[1px] border-white p-2 rounded-full `}
							>
								{userCheckHover && (
									<span className=" text-primary mr-2">ثبت نام</span>
								)}
								<FaUserCheck className="text-primary" />
							</NavLink>

							<NavLink
								to="#"
								onMouseEnter={() => setFileBreakHover(true)}
								onMouseLeave={() => setFileBreakHover(false)}
								className={`${st.linkBox} hover:gap-3 text-[15px] border-[1px] border-white p-2 rounded-full `}
							>
								{fileBreakHover && (
									<span className=" text-primary mr-2">تیکت</span>
								)}
								<BsTicketPerforatedFill className="text-primary rotate-90" />
							</NavLink>
						</ul>
					</div>
				</>
			) : (
				<section
					className={`${st.btnBox}  flex lg:flex-col items-center justify-end lg:items-end gap-2 lg:pt-10 2xl:pl-12 `}
					// onClick={toggleMenu}
				>
					<NavLink
						to="/"
						onMouseEnter={() => setProfileHover(true)}
						onMouseLeave={() => setProfileHover(false)}
						className={`${st.linkBox} hover:gap-3 text-[15px] border-[1px] border-white p-2 rounded-full `}
					>
						{profileHover && (
							<span className=" text-primary mr-2">پروفایل</span>
						)}
						<FaUser className="text-primary" />
					</NavLink>

					<NavLink
						to="#"
						onMouseEnter={() => setUserCheckHover(true)}
						onMouseLeave={() => setUserCheckHover(false)}
						className={`${st.linkBox} hover:gap-3  text-[15px] border-[1px] border-white p-2 rounded-full `}
					>
						{userCheckHover && (
							<>
								<span className=" text-primary w-max -ml-3">ثبت</span>
								<span className=" text-primary w-max">نام</span>
							</>
						)}
						<FaUserCheck className="text-primary" />
					</NavLink>

					<NavLink
						to="#"
						onMouseEnter={() => setFileBreakHover(true)}
						onMouseLeave={() => setFileBreakHover(false)}
						className={`${st.linkBox} hover:gap-3 text-[15px] border-[1px] border-white p-2 rounded-full `}
					>
						{fileBreakHover && (
							<span className=" text-primary mr-2">تیکت</span>
						)}
						<BsTicketPerforatedFill className="text-primary rotate-90" />
					</NavLink>
				</section>
			)}
		</nav>
	);
};
