import React from "react";
import loading from '../assets/images/loading.gif'

const Loading = () => {
  return <div className="flex justify-center text-white w-full">
  <img width={50} height={50} src={loading} alt=" loading..." />
</div>
};

export default Loading;
