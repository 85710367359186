import React from "react";
import { NavLink } from "react-router-dom";
import glp1 from "../../assets/images/glp1.png";
import glp2 from "../../assets/images/glp2.png";
import glp3 from "../../assets/images/glp3.png";
import poster from "../../assets/images/poster.png";
import { useMediaQuery } from "react-responsive";
import st from "../../styles/responsive.module.css"


const fakeDataImage =[{img : glp1},{img : glp2},{img : glp3},]


export const GallerySection = () => {

	const isResponsive = useMediaQuery({query:'(min-width:1024px)'})
	const isMed = useMediaQuery({query:'(max-width:790px)'})
	const isSml = useMediaQuery({query:'(max-width:573px)'})
	
	
	return (
		<section
			className={`${st.galleryMainGap} py-20 px-10 md:p-20 w-full gap-20 lg:gap-24 flex-col flex lg:flex-row lg:justify-between`}
		>
			{!isResponsive && (
				<div className="flex justify-between items-center">
					<h2 className="text-primary font-bold text-2xl lg:text-4xl">
						گالـــــری تصاویر
					</h2>
					<NavLink
						to={"/"}
						className="border-[1px] border-primary rounded-xl text-primary px-3 py-1"
					>
						تصاویر بیشتر{" "}
					</NavLink>
				</div>
			)}
			<section
				className={`${
					isMed ? "justify-evenly" : ""
				} flex flex-row lg:flex-col justify-between gap-3`}
			>
				{isMed
					? isSml
						? fakeDataImage.slice(0, 1).map((item) => (
								<div
									className={`${st.gallerySlider} overflow-hidden w-[200px] h-[200px] lg:w-[250px] lg:h-[250px] group relative rounded-3xl`}
								>
									<img
										className="w-full bg-cover bg-center "
										src={item.img}
										alt=" "
									/>
									<NavLink
										to={"/"}
										className={`transition duration-300 ease-in-out absolute top-0 w-full h-[100%] opacity-0 bg-shadowLayer group-hover:opacity-100 text-white flex justify-center items-end`}
									>
										<div className={`text-[20px] pb-4`}>
											<p>اسب دوانی </p>
											<p>1402/12/12</p>
										</div>
									</NavLink>
								</div>
								
						   ))
						: fakeDataImage.slice(0, 2).map((item) => (
								<div
									className={`${st.gallerySlider} overflow-hidden w-[200px] h-[200px] lg:w-[250px] lg:h-[250px] group relative rounded-3xl`}
								>
									<img
										className="w-full bg-cover bg-center "
										src={item.img}
										alt=" "
									/>
									<NavLink
										to={"/"}
										className={`transition duration-300 ease-in-out absolute top-0 w-full h-[100%] opacity-0 bg-shadowLayer group-hover:opacity-100 text-white flex justify-center items-end`}
									>
										<div className={`text-[20px] pb-4`}>
											<p>اسب دوانی </p>
											<p>1402/12/12</p>
										</div>
									</NavLink>
								</div>
						   ))
					: fakeDataImage.map((item) => (
							<div
								className={`${st.gallerySlider} overflow-hidden w-[200px] h-[200px] lg:w-[250px] lg:h-[250px] group relative rounded-3xl`}
							>
								<img
									className="w-full bg-cover bg-center "
									src={item.img}
									alt=" "
								/>
								<NavLink
									to={"/"}
									className={`transition duration-300 ease-in-out absolute top-0 w-full h-[100%] opacity-0 bg-shadowLayer group-hover:opacity-100 text-white flex justify-center items-end`}
								>
									<div className={`text-[20px] pb-4`}>
										<p>اسب دوانی </p>
										<p>1402/12/12</p>
									</div>
								</NavLink>
							</div>
							// <></>   
					   ))}
			</section>

			<section className="flex-grow flex flex-col items-center">
				{isResponsive && (
					<h2 className="text-primary text-4xl font-bold">
						گالــــری‌تصاویر
					</h2>
				)}

				<div className="relative w-full h-full overflow-hidden flex justify-center">
					<div
						className={`${st.videoTagMargin} overflow-hidden lg:m-10 rounded-[40px]`}
					>
						<video
							className="w-full h-full  object-cover"
							src=""
							poster={poster}
							controls
						/>
					</div>
				</div>

				{isResponsive && (
					<NavLink
						to={"/"}
						className="border-[1px] border-primary rounded-xl text-primary px-3 py-1"
					>
						تصاویر بیشتر{" "}
					</NavLink>
				)}
			</section>
		</section>
	);
};
